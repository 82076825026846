import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { minHeightForMenuPage, paddingForMenuPage } from "../defines";
import {
    List,
    Segment
} from "semantic-ui-react"

const nav = (path) => {
    navigate(path, { state: { category : "other" } });
}

const OtherPage = ({ mobile }) => (
    <>
        <SEO title="Other" />
        <Segment
            textAlign="center"
            vertical
            inverted
            style={{ minHeight: minHeightForMenuPage(mobile), padding: paddingForMenuPage(mobile) }}
        >
            <List divided inverted relaxed="very">
                <List.Item as="a" onClick={() => nav("/visualizations/other/vegan-society-trademarks")}>
                    <List.Content>
                        <List.Header>The Vegan Society’s Vegan Trademark</List.Header>
                        <List.Description>Yearly chart of per-category trademarks given out by the Vegan Society</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => nav("/visualizations/other/documentaries")}>
                    <List.Content>
                        <List.Header>Vegan documentaries</List.Header>
                        <List.Description>List of vegan documentaries sorted by category</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => nav("/visualizations/other/google-trends")}>
                    <List.Content>
                        <List.Header>Vegan Google trends</List.Header>
                        <List.Description>Chart of searches over time for vegan related terms on Google</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => nav("/visualizations/other/public-companies")}>
                    <List.Content>
                        <List.Header>Publicly traded companies</List.Header>
                        <List.Description>List of publicly traded Vegan/plant-based companies</List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Segment>
    </>
);

export default OtherPage;
